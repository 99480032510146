import {
  LoginAuthenticationService,
  ProfileFetchService,
  RefreshAuthTokenService,
} from "../../service/auth";
import { useAtom } from "jotai";
import { useToast } from "../../components/ui/use-toast";
import { userAtom } from "../../store/userAtom";
import { commonAtom } from "../../store/commonAtom";

const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

export const refreshAuthToken = async () => {
  console.log("Setting up token refresh");
  while (true) {
    try {
      const res = await RefreshAuthTokenService();
      const data = res?.data;
      const authToken = data?.access;
      if (authToken) {
        localStorage.setItem("token", authToken);
      }
      console.log("Refreshed auth token");
    } catch (error) {
      console.log("Error refreshing auth token:", error);
    }
    await sleep(1000 * 60 * 3);
  }
};

const UseAuthHook = () => {
  const { toast } = useToast();
  const [, setUser] = useAtom(userAtom);
  const [common, setCommon] = useAtom(commonAtom);

  const LoginHandler = async (username: string, password: string) => {
    const loginData = {
      username,
      password,
    };
    try {
      setCommon({ ...common, isLoading: true });
      const res = await LoginAuthenticationService(loginData);
      const data = res?.data;
      setCommon({ ...common, isLoading: false });
      toast({
        title: "Successfully Logged In",
        variant: "success",
      });
      localStorage.setItem("token", data.access);
      localStorage.setItem("refresh", data.refresh);
      // refreshAuthToken();
      const profileRes = await ProfileFetchService();
      const profile = profileRes?.data;
      setUser({
        ...(profile || {}),
        username: username,
        token: data.access,
        isAuthenticated: true,
      });
    } catch (error: any) {
      setCommon({ ...common, isLoading: false });
      toast({
        title: "Error",
        variant: "destructive",
        description: error.response.data.detail,
      });
    }
  };
  return { LoginHandler };
};

export default UseAuthHook;
