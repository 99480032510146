const component = {
  home: { url: "/" },
  login: { url: "/login" },
  signup: { url: "/sign-up" },
  forgetPassword: { url: "/forget-password" },
  dashboard: { url: "/dashboard" },
  profile: { url: "/profile" },
};

export default component;
