import LoginContainer from "../components/auth/LoginContainer";
import logo from "../assets/images/logo.svg";
import bgImageA from "../assets/images/bgImage1a.jpg";

export default function Login() {
  return (
    <div
      className="flex flex-col w-full h-screen bg-cover bg-center bg-no-repeat bg-fixed items-center justify-center overflow-scroll py-5"
      style={{
        backgroundImage: `url(${bgImageA})`,
      }}
    >
      <div className="w-[200px] mb-[60px]">
        <img src={logo} alt="logo" />
      </div>
      <div className="flex flex-col justify-center">
        <LoginContainer />
      </div>
    </div>
  );
}
