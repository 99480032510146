import { Route, Routes } from "react-router-dom";
import component from "../constants/urls";
import Page404 from "../components/pageNotFound/page404";
import Login from "../pages/Login";
import SignUp from "../pages/SignUp";
import ForgetPassword from "../pages/ForgetPassword";

export default function UnAuthRoute() {
  return (
    <Routes>
      <Route path={component.home.url} element={<Login />} />
      <Route path={component.login.url} element={<Login />} />
      <Route path={component.signup.url} element={<SignUp />} />
      <Route path={component.forgetPassword.url} element={<ForgetPassword />} />
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
}
