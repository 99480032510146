import { Link } from "react-router-dom";
import component from "../../constants/urls";
import { Button } from "../ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "../ui/card";
import { Input } from "../ui/input";
import { Label } from "../ui/label";

export default function SignupContainer() {
  const handleSignUp = (e: any) => {
    e.preventDefault();
  };
  return (
    <div className="login_container">
      <Card className="min-h-[40vh] lg:w-[35vw] lg:px-10 lg:py-10">
        <CardHeader>
          <CardTitle className="text-2xl text-foreground">
            Create your account
          </CardTitle>
        </CardHeader>
        <CardContent className="flex flex-col gap-6 lg:gap-9">
          <form className="flex flex-col gap-3 lg:gap-5">
            <div>
              <Label>Full Name</Label>
              <Input className="mt-2" type="text" placeholder="User Name" />
            </div>
            <div>
              <Label>Email address</Label>
              <Input className="mt-2" type="text" placeholder="email" />
            </div>
            <div>
              <Label>Password</Label>
              <Input className="mt-2" type="password" placeholder="******" />
            </div>
            <div>
              <Label>Re-enter Password</Label>
              <Input className="mt-2" type="password" placeholder="******" />
            </div>
            <Button className="w-full py-5" onClick={handleSignUp}>
              Sign Up
            </Button>
          </form>
          <div className="flex justify-between">
            <p className="text-sm text-primary font-medium hover:text-primary/80 transition-all">
              <Link to={component.login.url}>Login</Link>
            </p>
            <p className="text-sm text-primary font-medium hover:text-primary/80 transition-all">
              <Link to={component.forgetPassword.url}>Privacy Policy</Link>
            </p>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
